import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Takuache Files</h1>
        <h2>COMING SOON 👽</h2>
      </header>
    </div>
  );
}

export default App
